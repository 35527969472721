import React from "react";
import AuthScreen from "./../../components/common/AuthScreen";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import withAuth from "../../components/common/Auth/withAuth";

function Login() {
  return <AuthScreen mode="LOGIN" />;
}

export default withAuth(Login);

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
    },
  };
}
